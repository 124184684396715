









































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        PopoverInput,
        LsDialog,
    },
})
export default class Table extends Vue {
    $refs!: { popoverInput: PopoverInput[]; dialog: LsDialog }
    @Prop({ default: () => [] }) value!: any[]
    @Prop({ default: () => [] }) extend!: any
    price: any[] = []

    @Watch('value', { immediate: true })
    valueChange(val: any) {
        this.initPrice()
    }

    initPrice() {
        this.price = this.value.map((item) => {
            const obj: any = {}
            this.extend.price.forEach((kitem: any) => {
                obj[kitem.key] = item[kitem.key] || ''
            })
            return obj
        })
    }

    handleClose() {
        this.price = this.price.map(() => ({}))
        this.$refs.popoverInput.forEach((item) => {
            item.close()
        })
    }

    handleConfirm() {
        this.value.forEach((item, index) => {
            this.extend.price.forEach((kitem: any) => {
                this.$set(item, kitem.key, this.price[index][kitem.key])
            })
        })
    }

    batchSetting(value: string, fields: string) {
        this.price.forEach((item, index) => {
            this.$set(item, fields, value)
        })
    }
}
